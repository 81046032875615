<template>
  <v-row>
    <v-col
      cols="3"
    >
      <v-btn
        class="rounded-lg py-5"
        tile
        color="primary"
        block
        dark
      >
        <v-icon
          left
          class="pr-2"
        >
          mdi-card-account-details
        </v-icon>
        Neue Abwesenheit
      </v-btn>
      <v-select
        v-model="selectedType"
        :items="absenceType"
        label="Abwesenheitstype"
      />
      <v-menu
        ref="startMenu"
        v-model="startMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="start"
        transition="scale-transition"
        min-width="290px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="start"
            class="mt-3"
            label="Startdatum"
            dense
            readonly
            outlined
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="start"
          no-title
          :min="minDate"
          @input="startMenu = false; $refs.startMenu.save(start)"
        />
      </v-menu>
      <br>
      <v-menu
        ref="endMenu"
        v-model="endMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="end"
        transition="scale-transition"
        min-width="290px"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="end"
            label="Enddatum"
            readonly
            v-bind="attrs"
            class="mt-3"
            dense
            outlined
            hide-details
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="end"
          :min="minEndDate"
          no-title
          @input="endMenu = false; $refs.endMenu.save(end)"
        />
      </v-menu>
      <br>
      <v-text-field
        v-model="s_Comment"
        label="Kommentar"
        outlined
      />
      <div class="text-center">
        <v-btn
          class="mx-2"
          fab
          dark
          color="primary"
          small
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="9">
      <base-card>
        <v-card-title>Abwesenheiten</v-card-title>
        <v-card-text>
          <div>
            <v-sheet
              tile
              height="54"
              color="grey lighten-3"
              class="d-flex"
            >
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-select
                v-model="type"
                :items="types"
                dense
                outlined
                hide-details
                class="ma-2"
                label="type"
              />
              <v-select
                v-model="mode"
                :items="modes"
                dense
                outlined
                hide-details
                label="event-overlap-mode"
                class="ma-2"
              />
              <v-select
                v-model="weekday"
                :items="weekdays"
                dense
                outlined
                hide-details
                label="weekdays"
                class="ma-2"
              />
              <v-spacer />
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                @change="getEvents"
              />
            </v-sheet>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Absence',
    },
    data () {
      return {
        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [
          { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
          { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
          { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
          { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        value: '',
        events: [],
        colors: [
          'red',
          'red',
          'green',
          'green',
          'orange',
          'orange',
          'green',
          'green',
        ],
        names: [
          'Krank',
          'Krank',
          'Ferien',
          'Ferien',
          'Sonderaufgabe',
          'Sonderaufgabe',
          'Ferien',
          'Ferien',
        ],
        absenceType: [
          'Krank',
          'Ferien',
          'Sonderaufgabe',
        ],
        selectedType: null,
        dark: false,
        startMenu: false,
        start: new Date().toISOString().substr(0, 10),
        endMenu: false,
        end: new Date().toISOString().substr(0, 10),
        minWeeks: 1,
        now: null,
        nowDate: new Date().toISOString().substr(0, 10),
        s_Comment: '',
      }
    },
    computed: {
      minEndDate () {
        const Lt_Start = new Date(this.start)
        const Lt_End = new Date(this.end)
        if (Lt_End < Lt_Start) {
          this.end = Lt_Start.toISOString().substr(0, 10)
          return Lt_Start.toISOString().substr(0, 10)
        }
        return this.nowDate
      },
      minDate() {
        const today = new Date
        today.setDate(today.getDate() - 60)
        return today.toISOString().substr(0, 10)
      }
    },
    methods: {
      getEvents ({ start, end }) {
        const events = []

        const min = new Date(`${start.date}`)
        const max = new Date(`${end.date}`)
        const days = (max.getTime() - min.getTime()) / 86400000
        // const eventCount = this.rnd(days, days + 5);
        const eventCount = 5

        for (let i = 0; i < eventCount; i++) {
          const allDay = true
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)
          const name = this.names[this.rnd(0, this.names.length - 1)]
          events.push({
            name: name,
            start: this.formatDate(first, allDay),
            end: this.formatDate(second, allDay),
            color: this.colors[this.names.indexOf(name)],
          })
        }

        this.events = events
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      formatDate (a, withTime) {
        return withTime
          ? `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
          : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
      },
    },
  }
</script>
